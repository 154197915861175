<template>
  <div class="">
    <Loading v-if="isLoading"></Loading>
    <div class="flex justify-between items-center m-3 flex-grow-0 flex-shrink">
      <div class="flex">
        <div class="grid time-filter">
          <span>From: </span>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.startdatetime"
          />
          <input
            type="time"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.startTime"
          />
        </div>
        <div class="grid time-filter">
          <span>To: </span>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.enddatetime"
          />
          <input
            type="time"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.endTime"
          />
        </div>
      </div>
      <!-- component -->
      <div class="flex" v-if="paymentLogs">
        <button
          @click="fetchRecords"
          class="block mr-2 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
        >
          Get Records
        </button>
        <button
          @click="generateCSV"
          class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
        >
          Download
        </button>
      </div>
    </div>
    <h2 class="my-4 font-semibold text-blue-">Business Payment Log</h2>
    <form
      @submit.prevent="searchForMerchant"
      class="relative text-gray-600 mt-10 mb-4 w-7/12"
    >
      <input
        type="search"
        name="serch"
        placeholder="Search Merchant Name..."
        v-model="searchText"
        class="bg-white h-10 px-5 pr-10 w-full rounded-full text-sm"
      />
      <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
        <svg
          class="h-4 w-4 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 56.966 56.966"
          style="enable-background: new 0 0 56.966 56.966"
          xml:space="preserve"
          width="512px"
          height="512px"
        >
          <path
            d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
          />
        </svg>
      </button>
    </form>
    <div class="w-10/12 mx-auto mt-12" v-if="merchants">
      <div class="" v-if="merchants.length > 0">
        <div
          @click="selectBusiness(merchant)"
          class="shadow-lg m-4 flex items-center hover:bg-gray-300 cursor-pointer"
          v-for="(merchant, i) in merchants"
          :key="i"
        >
          <div class="flex justify-center items-center bg-gray-900 p-4 w-28">
            <h1 class="text-6xl uppercase">
              {{ merchant.name.substring(0, 1) }}
            </h1>
          </div>
          <div class="ml-4">
            <h2 class="text-gray-700 capitalize text-lg">
              {{ merchant.name }}
            </h2>
            <p class="text-xs mt-1 text-gray-500">
              {{ merchant.email }}
            </p>
          </div>
        </div>
      </div>
      <div class="h-18 flex justify-center items-center mt-56" v-else>
        <p class="text-gray-500 text-base">No match found...</p>
      </div>
    </div>
    <!--  -->
    <div class="" v-if="paymentLogs">
      <div
        class="overflow-scroll pb-6"
        style="height: 600px"
        v-if="paymentLogs && paymentLogs.length > 0"
      >
        <table class="shadow-lg bg-white w-full">
          <thead>
            <tr>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                ID
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Business ID
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Business Email
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Business Name
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Pricing Group
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Business Phone
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Payment Channel
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Payment Method
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Created At
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Updated At
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Is Payment Received
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Is Payoutt Disbursed
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Is Logged To Payout
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Date Payment Received
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Date Payout Disbursed
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Txn Type
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Txn Type ID
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Acquirer Bank
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Store ID
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Store Name
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Sale Agent
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Customer Name
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Payment Channel
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Card Type
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Card Number
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Payment Receipt
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Receipt URL
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Amount Paid
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Bank Name
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Bank Code
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Account Name
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Account No.
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Payment Date
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Payment Method
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Amount Paid
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Txn Fee
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Loan Fee
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Net Fee Due Merchant
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Sale Mode
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Offline Sale ID
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Offline Date
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Payment Ref.
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Recipient Detail Payment Ref. ID
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Disbusrsement Detail Payout Date
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Disbursement Detail Payout Date
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Disbursement Detail Payout Method
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Disbursement Detail Payout Agent ID
              </th>
              <th
                class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
              >
                Disbursement Detail Payout Agent Name
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(log, i) in paymentLogs"
              :key="i"
              class="hover:bg-gray-300"
            >
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log._id }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.business_id }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs lowercase">
                {{ log.business_email }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs uppercase">
                {{ log.business_name }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.pricing_group }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.business_phone }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.payment_channel }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.payment_method }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ moment(log.created_at).format("DD MMM YYYY - h:mm:ss a") }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ moment(log.updated_at).format("DD MMM YYYY - h:mm:ss a") }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.is_payment_received_confirmed }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.is_payout_disbursed_confirmed }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.is_logged_to_payout_table }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(log.date_payment_received).format(
                    "DD MMM YYYY - h:mm:ss a"
                  )
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(log.date_payout_disbursed).format(
                    "DD MMM YYYY - h:mm:ss a"
                  )
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.recipient_details.transaction_type }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.recipient_details.transaction_type_id }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.recipient_details.acquirer_bank }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.recipient_details.store_id }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.recipient_details.store_name }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.sale_agent }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.customer_details.customer_name }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.recipient_details.payment_details.channel }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.recipient_details.payment_details.card_type }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.recipient_details.payment_details.card_number }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.recipient_details.payment_details.payment_receipt }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs text-blue-500"
              >
                <p
                  v-if="
                    log.recipient_details.payment_details.receipt_url === 'na'
                  "
                >
                  {{ log.recipient_details.payment_details.receipt_url }}
                </p>
                <a
                  v-else
                  target="_blank"
                  :href="log.recipient_details.payment_details.receipt_url"
                  >{{ log.recipient_details.payment_details.receipt_url }}</a
                >
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  log.recipient_details.payment_details.amount_paid
                    | formatPrice(false)
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.bank_payment_details.bank_name }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.bank_payment_details.bank_code }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.bank_payment_details.account_name }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.bank_payment_details.account_number }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{
                  moment(log.recipient_details.payment_date).format(
                    "DD MMM YYYY"
                  )
                }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.payment_method }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.amount_paid | formatPrice(false) }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.transaction_fee | formatPrice(false) }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.loan_fee | formatPrice(false) }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{
                  log.recipient_details.net_fee_due_merchant
                    | formatPrice(false)
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.sale_mode }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.offline_sale_id }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(log.recipient_details.offline_date).format(
                    "DD MMM YYYY"
                  )
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.payment_reference }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs capitalize">
                {{ log.recipient_details.payment_reference_id }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(log.disbursement_details.payout_date).format(
                    "DD MMM YYYY"
                  )
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.disbursement_details.payout_method }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.disbursement_details.payout_agent_id }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ log.disbursement_details.payout_agent_name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-center items-center h-52" v-else>
        <p>No data available.</p>
      </div>
    </div>
    <div class="mt-3" v-if="paymentLogs && paymentLogs.length > 0">
      <button
        @click="prevPage(currentPage)"
        :disabled="currentPage === 1 ? isDisabled : !isDisabled"
        class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
        :class="
          currentPage === 1 ? 'bg-blue-50 text-black' : 'bg-blue-900 text-white'
        "
      >
        &laquo; Prev
      </button>

      <button
        @click="nextPage(currentPage)"
        class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        v-if="paymentLogs && paymentLogs.length > 99"
      >
        Next &raquo;
      </button>
    </div>
    <div
      v-if="alertDiv"
      class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
    >
      <div
        class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="currentColor"
          class="bi bi-exclamation-triangle-fill text-green-500 mx-auto"
          viewBox="0 0 16 16"
        >
          <path
            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
          />
        </svg>
        Please check your email in 5 mins for the download link.
        <br />Please note that it will take longer to generate a download link
        for larger date ranges.
        <button
          @click="alertDiv = false"
          class="float-right uppercase shadow mx-auto hover:bg-red-600 bg-red-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 rounded"
          style="width: inherit"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { json2excel } from "js2excel";
import moment from "moment";
import { mapActions } from "vuex";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      paymentLogs: null,
      dateData: null,
      selectedBusinessModalCtrl: false,
      searchText: "",
      merchants: null,
      selectedMerchant: null,
      alertDiv: false,
      currentPage: 1,
      isDisabled: false,
    };
  },
  components: {
    Loading,
  },
  created() {
    const d = new Date();

    this.dateData = {
      startdatetime: new Date(d.setDate(d.getDate() - 7))
        .toJSON()
        .split("T")[0],
      enddatetime: new Date().toJSON().split("T")[0],
      startTime: this.moment(d).format("HH:mm"),
      endTime: this.moment(d).format("HH:mm"),
      // enddatetime: new Date().toJSON().split("T")[0]
    };
  },
  methods: {
    ...mapActions([
      "FETCH_BUSINESS_PAYMENT_LOGS",
      "FETCH_PAYMENT_LOGS",
      "FETCH_SEARCH_MERCHANTS",
      "DOWNLOAD_BUSINESS_PAYMENT_LOGS_CSV",
      "FETCH_PAGINATED_BUSINESS_PAYMENT_LOGS",
    ]),
    async selectBusiness(merchant) {
      this.dateData.merchant = merchant;
      this.selectedMerchant = merchant.business_id;

      this.isLoading = true;
      await this.$store.dispatch("FETCH_BUSINESS_PAYMENT_LOGS", this.dateData);
      const res = this.$store.getters["GET_PAYMENT_LOGS_RES"];

      this.$store.commit("SETUP_PAYMENT_LOGS_RES", null);

      if (res.status) {
        this.merchants = null;

        const data = res.data ? res.data.items || [] : [];
        this.paymentLogs = data.sort((a, b) =>
          a.business_name.trim().toLowerCase() >
          b.business_name.trim().toLowerCase()
            ? 1
            : -1
        );
      } else {
        alert(
          res.error ||
            res.message ||
            "Error: Please check network or contact admin."
        );
      }

      this.isLoading = false;
    },
    async fetchRecords() {
      this.isLoading = true;
      this.dateData.merchant = this.selectedMerchant;
      await this.$store.dispatch("FETCH_PAYMENT_LOGS", this.dateData);
      const res = this.$store.getters["GET_PAYMENT_LOGS_RES"];

      if (res.status) {
        this.merchants = null;

        const data = res.data ? res.data.items || [] : [];
        this.paymentLogs = data.sort((a, b) =>
          a.business_name.trim().toLowerCase() >
          b.business_name.trim().toLowerCase()
            ? 1
            : -1
        );
      } else {
        alert(
          res.error ||
            res.message ||
            "Error: Please check network or contact admin."
        );
      }
      this.isLoading = false;
    },
    async searchForMerchant() {
      if (this.searchText.length < 3) {
        alert("Searched text must be at least three or more characters.");
        return;
      }
      this.isLoading = true;
      await this.$store.dispatch("FETCH_SEARCH_MERCHANTS", this.searchText);
      const res = this.$store.getters["GET_SEARCHED_MERCHANT_RES"];

      this.$store.commit("SETUP_SEARCHED_MERCHANTS_RES", null);

      if (res.status) {
        this.paymentLogs = null;
        const data = res.data ? res.data.items || [] : [];
        this.merchants = data.sort((a, b) =>
          a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1
        );
      } else {
        alert(
          res.error ||
            res.message ||
            "Error: Please check network or contact admin."
        );
      }

      this.isLoading = false;
    },
    async generateCSV() {
      try {
        this.isLoading = true;
        this.dateData.merchant = this.selectedMerchant;
        let res = await this.$store.dispatch(
          "DOWNLOAD_BUSINESS_PAYMENT_LOGS_CSV",
          this.dateData
        );
        if (res.status) {
          this.isLoading = false;
          this.alertDiv = true;
        } else {
          this.isLoading = false;
        }
      } catch (e) {
        console.error(e);
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async prevPage() {
      this.isLoading = true;
      this.dateData.merchant = this.selectedMerchant;
      this.dateData.page = this.currentPage - 1;

      try {
        let res = await this.$store.dispatch(
          "FETCH_PAGINATED_BUSINESS_PAYMENT_LOGS",
          this.dateData
        );

        if (res.status) {
          this.isLoading = false;
          this.currentPage -= 1;
          const data = res.data ? res.data.items || [] : [];
          this.paymentLogs = data.sort((a, b) =>
            a.business_name.trim().toLowerCase() >
            b.business_name.trim().toLowerCase()
              ? 1
              : -1
          );
        }
        this.isLoading = false;
      } catch (error) {}
      this.isLoading = false;
    },
    async nextPage() {
      this.isLoading = true;
      this.dateData.merchant = this.selectedMerchant;
      this.dateData.page = this.currentPage + 1;

      try {
        let res = await this.$store.dispatch(
          "FETCH_PAGINATED_BUSINESS_PAYMENT_LOGS",
          this.dateData
        );

        if (res.status) {
          this.isLoading = false;
          this.currentPage += 1;
          const data = res.data ? res.data.items || [] : [];
          this.paymentLogs = data.sort((a, b) =>
            a.business_name.trim().toLowerCase() >
            b.business_name.trim().toLowerCase()
              ? 1
              : -1
          );
        }
        this.isLoading = false;
      } catch (error) {}
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
